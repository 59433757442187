import React, { useState, useEffect } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import Swal from "sweetalert2";
import { usePlaidLink } from "react-plaid-link";
import { useSelector } from "react-redux";

export default function Redeem(props) {
  const userBalance = useSelector((state) => state.userBalance);

  const [payoutMethods, setPayoutMethods] = useState([]);
  const [payoutMethod, setPayoutMethod] = useState(null);

  const [redeemForm, setRedeemForm] = useState({ amount: "", email: "", crypto: "BTC" });
  const [errors, setErrors] = useState({});

  const [bankForm, setBankForm] = useState({
    bankAccID: "",
    name: "", //Account name
  });

  const [bankErrors, setBankErrors] = useState({
    bankAccID: "",
  });

  const getPayoutMethods = async () => {
    if (redeemForm.amount) {
      var response = await api.post("/withdraw/methods", {
        amount: redeemForm.amount,
      });

      if (response.status === 200) {
        setPayoutMethods(response.data.methods);
        if (!response.data.methods.filter((method) => method?.method_id === payoutMethod?.method_id).length) {
          setPayoutMethod(null);
        }
      }
    }
  };

  useEffect(() => {
    getPayoutMethods();
  }, [redeemForm.amount]);

  //Plaid methods start here

  const [linkToken, setLinkToken] = useState(null);

  const createLinkToken = async () => {
    var tokenResponse = await api.get("/dwolla/plaid/link/create");
    if (tokenResponse.status === 200) {
      var linkToken = tokenResponse.data.link_token;
      setLinkToken(linkToken);
      console.log(`Created Link Token: ${linkToken}`);
    }
  };

  useEffect(() => {
    if (createLinkToken && !linkToken) {
      createLinkToken().catch((err) => console.error(err));
    }
  }, []);

  const handlePlaidLinkSuccess = async (publicToken, metadata) => {
    console.log("publicToken", publicToken);
    console.log("metadata", metadata);

    var response = await api.post("/dwolla/plaid/funding-source/create", {
      publicToken: publicToken,
      accounts: metadata.accounts,
    });

    console.log(response);
    await getPayoutMethods();
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
  });

  //Plaid methods ends here

  const [redeemablAmount, setRedeemableAmount] = useState(0);
  const getRedeemableAmount = async () => {
    var response = await api.get("/get-redeemable-amount");
    if (response.status === 200) {
      setRedeemableAmount(response.data);
    }
  };

  useEffect(() => {
    getRedeemableAmount();
  }, []);

  const validateBankForm = () => {
    var valid = true;
    var errorsTmp = {};

    if (!bankForm.bankAccID) {
      errorsTmp = { ...errorsTmp, bankAccount: __("Please select a bank or add a new one") };
      valid = false;
    }

    setBankErrors(errorsTmp);
    return valid;
  };

  const submitRedeemRequest = async (ev) => {
    ev.preventDefault();
    props.updateSpinner(true);
    setErrors({});
    var valid = true;
    var errorsTmp = {};

    if (!parseFloat(redeemForm.amount)) {
      errorsTmp.amount = "Please enter the payout amount";
      valid = false;
    } else {
      if (!payoutMethod) {
        errorsTmp.method = "Please select a method";
        valid = false;
      } else {
        if (payoutMethod.method_id == "DIRECT:WireTransfer") {
          if (!redeemForm.email) {
            valid = false;
            errorsTmp.email = "Please enter your email";
          }
        } else if (payoutMethod.method_id == "Dwolla") {
          valid = validateBankForm();
          redeemForm.bankForm = bankForm;
        }
      }
    }
    setErrors(errorsTmp);
    if (!valid) {
      props.updateSpinner(false);
      return;
    }

    redeemForm.method = payoutMethod.method_id;
    var response = await api.post("/withdraw", redeemForm);
    props.updateSpinner(false);
    if (response.status === 200) {
      setRedeemForm({ amount: "", email: "", crypto: "BTC" });
      setPayoutMethods([]);
      setPayoutMethod(null);
      setBankForm({ bankAccID: "" });

      Swal.fire({
        title: "Success!",
        text: response.data.message,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "warning!",
        text: response.data.message,
        icon: "warning",
      });
    }
  };

  const updateRedeemForm = (value, field) => {
    setRedeemForm({ ...redeemForm, [field]: value });
  };

  return (
    <>
      <div className="container">
        <div className="access_control_area accordion">
          <div className="chimp-accordion accordion-item">
            <h2 class="accordion-header">
              <button type="button" aria-expanded="false" class="accordion-button">
                {__("Request a redeem")}
              </button>
            </h2>
            <div className="accordion-collapse">
              <div className="accordion-body">
                <div className="myaccount_input_area">
                  <div className="row">
                    <form onSubmit={submitRedeemRequest}>
                      <p>
                        {__(
                          "Sweepstake Coins must be played at least once before being eligible for redemption as a cash prize."
                        )}{" "}
                        <br />
                        {__("Sweepstake Coins can be redeemed for $1 USD.")}
                        <br />
                        <br />
                        <strong>{__("Your Sweepstake Coin balance: ")}</strong> SC {userBalance.cash_balance}
                        <br />
                        <strong>{__("Available to redeem: ")}</strong> SC {redeemablAmount}
                        <br />
                        <strong>{__("Unplayed balance: ")}</strong> SC {userBalance.cash_balance - redeemablAmount}
                        <br />
                      </p>
                      <div className="col-md-12">
                        <div className="user_blocks">
                          <div className="single_block twoItems">
                            <div className="item">
                              <div className="input_label">
                                {__("Redeem Amount")}
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="number"
                                name="amount"
                                placeholder={__("Amount")}
                                max={redeemablAmount}
                                value={redeemForm.amount}
                                onChange={(ev) => updateRedeemForm(ev.target.value, "amount")}
                                className={"form-control input_box " + (errors.amount ? "error" : "")}
                              />
                              <div class="errorMsg">{errors.amount}</div>
                            </div>
                            {payoutMethod?.method_id == "DIRECT:WireTransfer" && (
                              <div className="item">
                                <div className="input_label">
                                  {__("Email")}
                                  <span className="requried_icon"> *</span>
                                </div>

                                <input
                                  type="email"
                                  value={redeemForm.email}
                                  onChange={(ev) => updateRedeemForm(ev.target.value, "email")}
                                  placeholder={__("Email")}
                                  className={"form-control input_box " + (errors.email ? "error" : "")}
                                />
                                <div class="errorMsg">{errors.email}</div>
                              </div>
                            )}
                            {payoutMethod?.method_id == "TRIPLEA" && (
                              <>
                                <div className="item">
                                  <div className="input_label">
                                    {__("Crypto")}
                                    <span className="requried_icon"> *</span>
                                  </div>

                                  <select
                                    value={redeemForm.crypto}
                                    onChange={(ev) => updateRedeemForm(ev.target.value, "crypto")}
                                    className={"form-control input_box " + (errors.crypto ? "error" : "")}
                                  >
                                    <option value="BTC">BTC</option>
                                    <option value="ETH">ETH</option>
                                    <option value="LNBC">LNBC</option>
                                    <option value="USDC">USDC</option>
                                    <option value="USDC_TRC20">USDC_TRC20</option>
                                    <option value="USDT">USDT</option>
                                    <option value="USDT_TRC20">USDT_TRC20</option>
                                  </select>
                                  <div class="errorMsg">{errors.crypto}</div>
                                </div>

                                <div className="item">
                                  <div className="input_label">
                                    {__("Wallet Address")}
                                    <span className="requried_icon"> *</span>
                                  </div>

                                  <input
                                    type="text"
                                    value={redeemForm.accNumber}
                                    onChange={(ev) => updateRedeemForm(ev.target.value, "accNumber")}
                                    placeholder={__("Wallet Address")}
                                    className={"form-control input_box " + (errors.accNumber ? "error" : "")}
                                  />
                                  <div class="errorMsg">{errors.accNumber}</div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="other_method_area">
                        <ul>
                          {payoutMethods.map((method, index) => (
                            <li className={method?.method_id === payoutMethod?.method_id ? "active" : ""}>
                              <input
                                id={"all_payout_" + method.id}
                                onChange={() => {
                                  setPayoutMethod(method);
                                }}
                                type="radio"
                                checked={method?.method_id === payoutMethod?.method_id}
                              />
                              <label htmlFor={"all_payout_" + method.id}>
                                <img alt="" src={method.logo} /> {method.method}
                              </label>
                            </li>
                          ))}
                        </ul>
                        <div class="errorMsg">{errors.method}</div>
                        <div
                          className={
                            "credit_card_from_area " + (payoutMethod?.method_id == "Dwolla" ? "d-block" : "d-none")
                          }
                        >
                          {payoutMethod?.fundingSources && (
                            <div className="existing_accounts">
                              <div className="subheading">
                                {__("Select a bank account")}
                                <span className="requried_icon">*</span>
                              </div>
                              {payoutMethod?.fundingSources.map((item, index) => (
                                <div className="bank_account_list_item">
                                  <a
                                    className={
                                      "common_button bank_account_name " +
                                      (bankForm.bankAccID === item._links.self.href ? "active" : "")
                                    }
                                    onClick={() => setBankForm({ ...bankForm, bankAccID: item._links.self.href })}
                                  >
                                    {item.name}
                                  </a>
                                </div>
                              ))}
                              <div className="bank_account_list_item">
                                <a className="common_button bank_account_name" onClick={() => openPlaidLink()}>
                                  {__("New account")}
                                </a>
                              </div>

                              <div className="errorMsg">{bankErrors.bankAccount}</div>
                            </div>
                          )}
                        </div>

                        <div className="center">
                          <button type="submit" className="common_btn gray">
                            {__("Submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
